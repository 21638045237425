<template> 
    <div class="section-wrap">

        <div class="form" v-show="!loading">
            <StepHeader :stepDetails="stepHeaderConfig" ref="stepHeader"></StepHeader>

            <div v-for="stepConfig in stepsConfig" v-bind:key="stepConfig.Id">
                <v-form :ref="stepConfig.Id" v-model="stepConfig.isValid" lazy-validation>
                    <v-container v-if="(stepHeaderConfig.currentStep - 1) == stepConfig.Order">
                        <v-row justify="center">
                            <v-col cols="12">
                                <v-card elevation="2" class="rounded-tr-0 rounded-tl-0 v-card-form"> 
                                    <v-row justify="center">
                                        <v-col cols="12" sm="8" lg="6">
                                            <v-card-text class="step1-form-wrapper" :class="quoteFunnelURLIdentifier">
                                                <!-- <v-row class="mb-3">
                                                    <v-col cols="12" class="d-flex justify-end pa-0">
                                                        <v-btn text color="primary" @click="fetchQuote" class="btn-lower-case btn-text-compact justify-self-end pa-0">
                                                            Fetch Previous Quote 
                                                            <v-icon v-if="!quoteFetchPanel">mdi-chevron-down</v-icon>
                                                            <v-icon v-else>mdi-chevron-up</v-icon>
                                                        </v-btn>                                                        
                                                    </v-col>
                                                    <v-col cols="12" class="pa-0">
                                                        <v-scale-transition>
                                                            <v-card elevation="0" v-if="quoteFetchPanel" color="ml_lightgray">
                                                                <v-card-title class="text-center body-1 d-flex justify-center">
                                                                    <b>Have a previous quote? </b> &nbsp; Enter your email here.
                                                                </v-card-title>
                                                                <v-card-text class="d-flex flex-row flex-wrap">
                                                                    <v-row class="py-3">
                                                                        <v-col cols="12" md="auto" class="flex-grow-1 py-0">
                                                                            <v-text-field class="bypassValidation" color="white" filled solo dense v-model="quoteEmailOrPhone" label="Email Address" :rules="EmailOrPhonerequired" :error-messages="emailOrPhoneErrors">
                                                                                <template v-slot:message>
                                                                                    <ul class="messages-list"> 
                                                                                        <li v-for="(error, index) in emailOrPhoneErrors" :key="index">{{ error }}</li>
                                                                                    </ul>
                                                                                </template>
                                                                            </v-text-field>
                                                                        </v-col>
                                                                        <v-col cols="12" md="auto" class="py-0 text-center">
                                                                            <v-btn outlined color="ml_darkergray" :loading="quoteFetchProcessing" :disabled="quoteFetchProcessing" @click="isValidEmailOrPhoneNumber">Fetch Saved Quote</v-btn>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-card-text>
                                                            </v-card>
                                                        </v-scale-transition>
                                                    </v-col>
                                                </v-row> -->
                                                
                                                <v-btn block color="primary" elevation="5" class="mb-6 btn-wrap" @click="goToQFCustomLink" v-if="currentQuoteFunnel !== null && currentQuoteFunnel.ShowButton">
                                                    {{currentQuoteFunnel.ButtonLabel}}
                                                </v-btn>
                                                <v-btn block color="primary" elevation="5" class="mb-6 btn-wrap" @click="goToEmployeer" v-else-if="!employeeToken && partnerId !== '999999' && enrollmentCode !== '99-99-03-1111'">
                                                    Have Pet insurance through your employer? Click Here
                                                </v-btn>
                                                <v-btn block color="primary" elevation="5" class="mb-6 btn-wrap" @click="goToEmployeerMyPets" v-else-if="employeeToken != 'nonSSOtoken' && partnerId !== '999999'">
                                                    Looking for a previously purchased policy? Click here
                                                </v-btn>
                                                <v-btn block color="primary" elevation="5" class="mb-6 btn-wrap" @click="goToDTC" v-else-if="partnerId === '999999' && enrollmentCode !== '99-99-03-1111'">
                                                    If your employer doesn't offer pet insurance, Click Here
                                                </v-btn>
                                                <div class="element-container">
                                                    <div class="CustomElementIdParagraph">
                                                        <p role="heading" aria-level="2">Tell us more about your best friend</p>
                                                    </div>
                                                </div>
                                                <div :id="guidToName(element.Id)" class="element-container" v-for="element in stepConfig.Elements" v-bind:key="element.Id">
                                                    <pet-species ref="AddPetSpecies" class="mb-4" :validateSpecies="validateChildComponents" v-if="element.Id === elementGuid.ElementIdAnimal" @isExotic="isExotic($event)"/>                               
                                                    <pet-name class="mb-3" v-else-if="element.Id === elementGuid.ElementIdPetName && !isExoticPet" />
                                                    <pet-age class="mb-3" v-else-if="element.Id === elementGuid.ElementIdAge && !isExoticPet" />
                                                    <pet-color class="mb-3" v-else-if="element.Id === elementGuid.ElementIdColor && !isExoticPet" />
                                                    <pet-gender ref="AddPetGendre" class="mb-4" :validateGender="validateChildComponents" v-else-if="element.Id === elementGuid.ElementIdGender && !isExoticPet" />
                                                    <pet-breed-type ref="AddPetBreed" class="mb-4" :validateBreedType="validateChildComponents" v-else-if="element.Id === elementGuid.ElementIdBreed && !isExoticPet" />
                                                    <user-email-address class="mb-3" v-else-if="element.Id === elementGuid.ElementIdEmail && !isExoticPet" /> 
                                                    <user-zip-code class="mb-3" v-else-if="element.Id === elementGuid.ElementIdZipCode && !isExoticPet" />
                                                    <user-address class="mb-3" v-else-if="element.Id === elementGuid.UserEmailAddress && !isExoticPet" />
                                                    <user-first-name class="mb-3" v-else-if="element.Id === elementGuid.ElementIdFirstName && !isExoticPet" />
                                                    <user-last-name class="mb-3" v-else-if="element.Id === elementGuid.ElementIdLastName && !isExoticPet" />
                                                    <user-phone class="mb-3" v-else-if="element.Id === elementGuid.ElementIdPhone && !isExoticPet" />
                                                    <custom-paragraph class="mb-3" :value-text.sync="element.Inputs" v-else-if="element.Id === elementGuid.CustomElementIdParagraph && !isExoticPet" />
                                                </div>
                                                <div class="element-container">
                                                    <user-employeer class="mb-3" v-if="showPartnerSearch && !isExoticPet"></user-employeer>
                                                </div>
                                            </v-card-text>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </div>
            <v-footer ref="controlZoomLevel" app color="white" elevation="2" v-if="!isExoticPet">
                <v-row class="d-flex justify-center align-center">
                    <v-col cols="auto" v-if="currentStep > 0">
                        <v-btn @click="changeStep(false)">Back: {{stepFooterConfig.prevActionText}}</v-btn>
                    </v-col>
                    <v-col cols="auto" v-if="stepsConfig && currentStep < stepsConfig.length">
                        <v-btn color="primary" @click="changeStep(true)" ref="nextBtn">
                            Next: {{stepFooterConfig.nextActionText}}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-footer>
        </div>
        <DotLoader :loading="loading" :loaderText="loaderText" :onLoadingComplete="focusHeader"/>
       
        <v-overlay class="cookie-overlay" :z-index="zIndex" :value="showCookieOverlay">
            <v-footer app>
                <v-row class="d-flex pa-5">
                    <v-col
                    class="text-center justify-center align-center"
                    cols="12"
                    aria-modal="true" role="dialog" id="cookieBannerForm" aria-labelledby="cookieBannerTitle">
                        <p class="d-inline-block mr-2" 
                        id="cookieBannerTitle"
                        >This website stores data such as cookies to enable important site functionality including analytics, targeting, and personalization.</p>
                        <div class="d-inline-block">
                            <v-btn class="mr-2" color="primary" @click="persistCookie()" ref="acceptCookieBtn" @keydown="handleAcceptCookieKeydown">
                                Accept
                            </v-btn>
                            <span class="d-sr-only" aria-live="polite">{{acceptSRAnnouncement}}</span>
                        </div>
                    </v-col>
                    
                </v-row>
            </v-footer>
        </v-overlay>
        <v-dialog
            v-model="showCustomerSearch"
            max-width="600"
            class="form-dialog-policy-wrapper"
            content-class="form-dialog-policy"
            persistent
            @keydown.esc="escSearchForExistingCustomer()"
            id="customerSearchDialog"
        >
            <template v-slot:default="dialog">
                <v-card 
                    id="customerSearchCardContainer"
                    :ripple="false"
                >
                    <v-card-title>
                        <span role="heading" aria-level="2" id="customerSearchDetailTitle">
                            Search for your existing policy
                        </span>
                        <span id="customerSearchDetailDesc" class="d-sr-only">Please enter name, last name, phone, or email to search. (At least one).</span>
                    </v-card-title>
                    <v-divider role="none"></v-divider>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                        label="First Name"
                                        id="existingUserName"
                                        ref="existingUserName"
                                        aria-describedby="existing-user-name-message"
                                        :rules="existingUserRules.name"
                                        v-model="existingUserFirstName"
                                        persistent-hint
                                        autocomplete="off"
                                        clearable
                                        @keydown="handleShiftTabOnModalFirstElement"
                                    >
                                        <template v-slot:message="{ message,key}">
                                            <span id="existing-user-name-message">{{message}}</span>
                                        </template>
                                    </v-text-field>
                                    <v-text-field
                                        label="Last Name"
                                        id="existingUserLastname"
                                        ref="existingUserLastname"
                                        aria-describedby="existing-user-lastname-message"
                                        :rules="existingUserRules.lastname"
                                        v-model="existingUserLastName"
                                        persistent-hint
                                        autocomplete="off"
                                        clearable
                                        @keydown="focusClearIconWhenTabbedFromInput"
                                    >
                                        <template v-slot:message="{ message,key}">
                                            <span id="existing-user-lastname-message">{{message}}</span>
                                        </template>
                                    </v-text-field>
                                    <v-text-field
                                        label="Phone Number"
                                        id="existingUserPhone"
                                        ref="existingUserPhone"
                                        aria-describedby="existing-user-phone-message"
                                        hint="Enter 10 digit phone number."
                                        v-mask="'###-###-####'"
                                        :rules="existingUserRules.phone"
                                        v-model="existingUserPhone"
                                        persistent-hint
                                        autocomplete="off"
                                        clearable
                                        @keydown="focusClearIconWhenTabbedFromInput"
                                    >
                                        <template v-slot:message="{ message,key}">
                                            <span id="existing-user-phone-message">{{message}}</span>
                                        </template>
                                    </v-text-field>
                                    <v-text-field
                                        label="Email"
                                        id="existingUserEmail"
                                        ref="existingUserEmail"
                                        aria-describedby="existing-user-email-message"
                                        :rules="existingUserRules.email"
                                        hint="name@example.com"
                                        type="email"
                                        v-model="existingUserEmailAddress"
                                        persistent-hint
                                        autocomplete="email"
                                        clearable
                                        @keydown="focusClearIconWhenTabbedFromInput"
                                    >
                                        <template v-slot:message="{ message,key}">
                                            <span id="existing-user-email-message">{{message}}</span>
                                        </template>
                                    </v-text-field>
                                    <v-alert 
                                        v-if="existingUserSearchHasError"
                                        border="top"
                                        color="#CC4745"
                                        dark
                                        class="mt-2"
                                    >
                                        {{existingUserSearchErrorMessage}}
                                    </v-alert>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-divider role="none"></v-divider>
                    <v-card-actions>
                        <v-row>
                            <v-col cols="12" class="text-center">
                                <v-btn color="primary" class="ma-1 btn-wrap" @click="searchForCustomer()">
                                    {{searchCustomerButton}}
                                </v-btn>
                                <v-btn color="error" class="ma-1 btn-wrap" @keydown="handleTabOnModalLastElement" @click="cancelSearchForExistingCustomer()">
                                    Cancel
                                </v-btn>
                            </v-col>                        
                        </v-row>
                       
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>
    </div>
</template>

<script>
    import StepHeader from '../../components/shared/StepHeader.vue';
    import SendQuote from '../../components/shared/SendQuote.vue';
    import DotLoader from '../../components/shared/DotLoader.vue';
    import {
        PetSpecies, PetGender, PetName, PetAge, PetColor, PetBreedType, UserEmailAddress, UserZipCode, UserEmployeer,
        UserAddress, UserFirstName, UserLastName, UserPhone, CustomParagraph, WorkEmailAddress, PaymentMethod
    } from '../../components/index';
    
    import renderEngine from '../Global/JS/renderEngine.js';

    export default {
        name: 'RenderEngine',
        mixins: [renderEngine],
        components: {
            PetSpecies, PetGender, PetName, PetAge, PetColor, PetBreedType, UserEmailAddress, UserZipCode, UserEmployeer,
            UserAddress, UserFirstName, UserLastName, UserPhone, CustomParagraph, StepHeader, WorkEmailAddress, DotLoader, SendQuote
        },
        data: () => ({
            
        }),        
        computed: {
            
        },
        created() {

        },
        watch: {

        },
        beforeDestroy(){

        },
        mounted() {
            
        },
        methods: {
            failedValidationFocus(){
                let failedValidation = document.querySelector(".v-input.error--text:not(.bypassValidation)");
                    
                if(failedValidation !== null){
                    if(failedValidation.classList.contains('hidden-input')){
                        failedValidation.parentNode.querySelector("button.v-btn").focus();
                    }else{
                        failedValidation.querySelector("input").focus();
                    }
                }
            },
        },
        updated(){
            
        }
    }
</script>
<style>
    .v-text-field .v-input__icon--clear {
        opacity: 1  !important;
    }
    ul.messages-list{
        list-style: none;
        padding: 5px 0 0 0;
    }
    .btn-text-compact{
        padding: 0px !important;
    }
</style>